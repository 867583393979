import "whatwg-fetch";
import { getUserId } from "./session";
import { apiURL, rbApiUrl } from "./constants";
import { REST } from "./rest";
import { IListing } from "./api/listing";

export const getConsumerData = async (queryParam = ''): Promise<IConsumerData> => {
  const userId = await getUserId();
  if (userId !== null || userId !== "null") {
    const consumerData = await REST.get(
      `${apiURL}/v1/consumer.json?id=${userId}${queryParam !== '' ?`&${queryParam}` : ''}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.data.data);

    return consumerData;
  } else {
    return null;
  }
};

export const getInverseLogo = async (partnerKey: string) => {
  try {
    const { data } = await REST.get(
      `${apiURL}/v2/partner_config.json?partner_key=${partnerKey}&resources=["gui_settings"]`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getFrontEndSettings = async (partnerKey: string) => {
  try {
    const { data } = await REST.get(
      `${apiURL}/v2/partner_config.json?partner_key=${partnerKey}&resources=front_end_settings&multipart=true`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    localStorage.setItem('hide_apply_now', data.data?.front_end_settings?.apply_now_rules?.hide_apply_now)
    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getFavoriteListings = async () => {
  const consumerData = await getConsumerData("favorites=true");
  return consumerData["saved_listings"];
};

export const getAgentInfo = async () => {
  const consumerData = await getConsumerData();
  return consumerData["agent_info"] || {};
};

export const setPicturesOrder = async ({ listingId, fileList }) => {
  const formData = new FormData();
  formData.append("listing_id", listingId);
  formData.append("file_list", JSON.stringify(fileList));

  return await REST.put(`${apiURL}/v1/listing_photos.json`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param pictureId
 * @param listingId
 * @param direction {string} Rotation direction. Can be "left" or "right"
 * @returns {Promise<*>}
 */
export const rotatePicture = async ({ pictureName, listingId, direction }) => {
  return await REST.put(
    `${rbApiUrl}/webimage/${pictureName}?direction=${direction}&listing_id=${listingId}`,
    {}
  ).then((res) => {
    return res.data;
  });
};

export const postPartnerPicture = async ({ partner, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("partner", partner);

  return await REST.post(`${apiURL}/v1/photos.json`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    return res.data;
  });
};

export const postComment = async (payload) => {
  return await REST.post(`${rbApiUrl}/comment`, payload).then((res) => {
    return res.data;
  })

}

export const getStatisticsData = async (listingId) => {
  return await REST.get(`${rbApiUrl}/applynow/statistics/listing/${listingId}`).then((res) => {
    return res.data
  })
}

export const updateListingAlert = async (alertId) => {
    return await REST.put(`${rbApiUrl}/listing_alert/${alertId}`, 
    {
        "alert_status": "Resolved"
    }).then((res) => {
    return res.data
  })
}
export interface IConsumerData {
  agent_info: any;
  allow_syndication: boolean;
  email: string;
  first_name: string;
  full_bp_access: boolean;
  hide_partner_cp: boolean;
  hide_rb_cp: boolean;
  id: number;
  is_admin: boolean;
  is_agent: boolean;
  is_owner: boolean;
  last_name: string;
  listing_mgmt_enabled: boolean;
  phone: string;
  saved_listings: IListing[];
  saved_ui_props: any;
  similar_listings: any[];
  renter_insurance: string;
}
