import React from "react";
import {agentInfoApi} from "../../../services/api/agent_info";
import {IListing, ListingType} from "../../../services/api/listing";
import {getConsumerData, getFrontEndSettings} from "../../../services/api";
import { partnerConfigApi } from "../../../services/api/partner_config";

function priceFormatter(cell = 0) {
  return (
    <span>{(0 === cell) ? "N/A" : "$" + cell.toFixed()}</span>
  );
}

const headerSortingClasses = (column, sortOrder, isLastSorting) => (
  isLastSorting ? 'lm__thead-sort-selected' : 'lm__thead'
);

export const getApplyNowRules = async (partnerKey) => {
  const frontEndSettings = await getFrontEndSettings(partnerKey);
  return frontEndSettings?.data?.front_end_settings?.apply_now_rules
}

export const getAgentPartnerKey = async () => {
  const consumerData = await getConsumerData();
  const userId = consumerData['id'];
  const agentInfo = await agentInfoApi.get(userId);
  return agentInfo.partner_key
};

export const getAgentName = async () => {
  const consumerData = await getConsumerData();
  const userId = consumerData['id'];
  const partnerKey = await agentInfoApi.get(userId);
  return partnerKey.full_name
};

export const getAgentEmail = async () => {
  const consumerData = await getConsumerData();
  const userId = consumerData['id'];
  const partnerKey = await agentInfoApi.get(userId);
  return partnerKey.email
};

export const addressFormatter = () => {
  return (
    <div><a href={"/agent/subscriptions_and_payment"}>Upgrade</a> your account to see the address</div>
  );
};

export const getFormattedDate = (date) => {
  // return date.toJSON().split("T")[0];
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  return date.toISOString().split('T')[0]
};

export const sortCaretFx = (order) => {
  if (!order) return (<i className="fas fa-chevron-down lm__sort-caret" />);
  else if (order === 'desc') return (<i className="fas fa-chevron-up lm__sort-caret" />);
  else if (order === 'asc') return (<i className="fas fa-chevron-down lm__sort-caret" />);
  return null;
};

export const removeDuplicates = (listings) => {

  const ids = [];
  const purgedListings = [];

  listings.map(function(listing) {
    if( !ids.includes(listing.apartment_id) ) {
      purgedListings.push(listing);
    }

    ids.push(listing.apartment_id);
  });

  return purgedListings;
};

export const areCoordsValid = (listing) => {
  return (
      listing.latitude &&
      listing.longitude &&
      0 !== listing.latitude &&
      0 !== listing.longitude
  );
};

export const stateList = async () =>{
  let userId = sessionStorage.getItem('user-id');
  if (userId == 'null' || userId === null){
    const consumerData = await getConsumerData();
    if (consumerData && consumerData['id']) {
      userId = ''+consumerData['id'];
    }
  }

  let isValidateToken = true;
  let validateToken = sessionStorage.getItem('validate-token');
  if (validateToken != 'null' || validateToken !== null) {
    isValidateToken = (validateToken == 'true');
  }
  const key = await agentInfoApi.get(userId, isValidateToken);
  const partnerInfo = await partnerConfigApi.get(key.partner_key, [ "front_end_settings"]);
  if(partnerInfo.front_end_settings?.default_location?.state_list){
    return JSON.parse(partnerInfo.front_end_settings.default_location.state_list);
  }
};

export const isAutocompleteEnabled = async (): Promise<boolean> => { 
  const consumerData = await getConsumerData()
  const userId = consumerData['id']
  const key = await agentInfoApi.get(userId)
  const partnerInfo = await partnerConfigApi.get(key.partner_key, ["status_tracker_config"])

  if (partnerInfo.status_tracker_config?.autocomplete_enabled) {
    return partnerInfo.status_tracker_config?.autocomplete_enabled
  }
}

export const getColumns = (listingType: ListingType, onCellClick: (e: Event, listingType: ListingType, row: any) => void) => {

  const city = { text: 'City', dataField: 'city', align: 'center', sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center'};
    }
  };

  const state = { text: 'State', dataField: 'state', align: 'center', sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center'};
    }
  };

  const rent = { text: 'Rent', dataField: 'rent', align: 'center', formatter: priceFormatter, sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center', width: '100px'};
    }
  };

  const beds = { text: '# Beds', dataField: 'beds_count', align: 'center', sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events:{onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center', width: '100px'};
    }
  };

  const pics = { text: '# Pics', dataField: 'pics.length', align: 'center', sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center', width: '100px'};
    }
  };

  const commission = { text: 'Commission', dataField: 'long_term_commission', align: 'center', formatter: priceFormatter, sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center'};
    }
  };

  const rbAddress = { text: 'Address', dataField: '', align: 'center', headerClasses: 'lm__thead', formatter: addressFormatter, events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return { textAlign: 'center' };
    }
  };

  const mlsAddress = { text: 'Address', dataField: 'address', align: 'center', sort: true, headerSortingClasses, sortCaret: sortCaretFx, headerClasses: 'lm__thead', events: {onClick: (e, column, columnIndex, row) => {onCellClick(e, listingType, row)}},
    headerStyle: (_colum, _colIndex) => {
      return {textAlign: 'center'};
    }
  };

  return ("rb" === listingType) ? [city, state, rent, beds, pics, commission, rbAddress] : [city, state, rent, beds, pics, mlsAddress];
};

export interface IResult {
  listings: IListing[],
  location: any,
}

export const getPackagedResults = (listings) => {

  let map = {};

  listings.forEach(function(listing){

    let locationStr = `${listing.latitude},${listing.longitude}`;

    if( !map[locationStr] ) {
      map[locationStr] = {
        listings: [],
        location: {lat: listing.latitude, lon: listing.longitude}
      }
    }

    map[locationStr].listings.push(listing);

  });

  let arr = [];
  for(let locationStr in map) {
    arr.push( map[locationStr] )
  }

  return arr;
};

export const getResultId = (result) => {
  const lat = JSON.stringify(result.location.lat).substr(0,8);
  const lon = JSON.stringify(result.location.lon).substr(0,9);
  return `${lat},${lon}`;
};

export function isDefinedAndNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export const removePrefix = (str, prefix) => {
  if (str.startsWith(prefix)) {
    return str.substring(prefix.length);
  }
  return str;
};

export function convertToUnderscoreLowerCase(str) {
  // Replace spaces and special characters with underscores
  const underscoredStr = str.replace(/[^\w\s]/g, '_').replace(/\s+/g, '_');

  // Convert to lowercase
  const lowerCaseStr = underscoredStr.toLowerCase();

  return lowerCaseStr;
}