import React, { useEffect, useState } from "react";
import "./rb_modern_header.scss";
import {
  handleUserSessionOnPageFocus,
  logout,
  updateSessionInfo,
} from "../../../services/session";
import { imgUrl } from "../../../services/constants";
import { BrokerLegacySettings } from "./broker_legacy_settings/broker_legacy_settings";
import { MobileNav } from "./mobile_nav/mobile_nav";
import { useUserData } from "../../components/hooks/use_user_data";
import { getConsumerData, getInverseLogo, IConsumerData } from "../../../services/api";
import { RB_FEATURES } from "../../components/admin/types";
import { createUseStyles } from "react-jss";
import { DynamicNav } from "./dynamic_nav/dynamic_nav";
import { WhiteLabelStyles } from "../../../services/api/partner_config";
import { PartnerConfig } from "../../../services/types";
import { DuplicateEmailDialog } from "../broker_search/components/duplicate_email_modal";
import { getAgentPartnerKey, getApplyNowRules } from "../../components/listing_search/utilities";
import { IGUISettings } from "./types_rb_modern_header/RbModernHeaderTypes";

//Set Default styles
const useHeaderStyles = createUseStyles({
  bpWlHeader: (props: WhiteLabelStyles) => ({
    backgroundColor: props.bpWlHeader.backgroundColor,
    backgroundImage: props.bpWlHeader.backgroundImage
      ? `url('${imgUrl}/partners/${props.bpWlHeader.backgroundImage}')`
      : "none",
    backgroundPosition: props.bpWlHeader.backgroundPosition,
    color: props.bpWlHeader.color,
  }),
  bpWlUserAccount: (props: WhiteLabelStyles) => ({
    "& a, .rb__logout": {
      // color: props.bpWlUserAccount.linkColor
      color: props.bpWlHeader.color,
    },
    "& i": {
      color: "white",
    },
    // color: props.bpWlUserAccount.color
    color: props.bpWlHeader.color,
  }),
});

export function WhiteLabelHeader() {
  const { userData, initUserData } = useUserData();

  const [title, setTitle] = useState("RB");
  const partnerKey = JSON.parse(
    $("body").attr("data-partner-config")
  ).partner_key;
  const [partnerConfig, setPartnerConfig] = useState({
    bp_ui_settings: {
      styles: {
        bpWlHeader: {},
        bpWlFooter: {},
        bpWlUserAccount: {},
      },
    },
  } as PartnerConfig);

  const [legacyMobileItems, setLegacyMobileItems] = useState(
    [] as HeaderMenuItem[]
  );
  const [brokerMobileItems, setBrokerMobileItems] = useState(
    [] as HeaderMenuItem[]
  );

  const [guiSettings, setGuiSettings] = useState<IGUISettings>();

  const [featuresAccess, setFeaturesAccess] = useState([] as RB_FEATURES[]);
  const styles = useHeaderStyles(partnerConfig.bp_ui_settings.styles);

  const handleUserSessionUpdate = (e: CustomEvent<IConsumerData>) => {
    initUserData();
  };

  const setupWhiteLabelSettings = async () => {
    // Fetch Partner Config UI Settings....
    const applyNowRules = await getApplyNowRules(partnerKey);
    const consumerData = await getConsumerData();
    // END
    const partnerConfigRes = $("body").attr("data-partner-config");
    const jsonRes = JSON.parse(partnerConfigRes);
    const partnerConfigJson = jsonRes.gui_settings as PartnerConfig;
    sessionStorage.setItem('branding_logo', partnerConfigJson?.logo_path)

    const hideApplyNow = applyNowRules?.hide_apply_now
    const isApplications = hideApplyNow ? 'applications' : ''
    const filteredFeatures = jsonRes.features.filter(feature => feature !== isApplications)

    setFeaturesAccess(filteredFeatures);
    setPartnerConfig(partnerConfigJson);
    setTitle(partnerConfigJson.title);
    if (consumerData.email)
      localStorage.setItem('hidden_tab', isApplications)
    else
      localStorage.removeItem('hidden_tab')
  };

  const getLogoImg = (logoPath: string) => {
    if (logoPath) {
      const imgLogoUrlPath =`${imgUrl}/partners/${logoPath}`;
      return <img className="rb__logo" src={imgLogoUrlPath} alt="RB Logo" />;
    }
  };

  const initPageBackground = () => {
    const els = Array.from(
      document.getElementsByClassName(
        "main-yield"
      ) as HTMLCollectionOf<HTMLElement>
    );
    els.forEach((el) => {
      el.style.backgroundImage = `url('${imgUrl}/beast_doodle_broker_search2.png')`;
    });
  };

  async function getLogo() {
    try {
      const partnerKey = await getAgentPartnerKey();

      if (partnerKey !== "") {
        const response = await getInverseLogo(partnerKey);

        if (response.code === 200) {
          setGuiSettings(response.data?.gui_settings);
        }
        return response;
      }
    } catch (error) {
      console.log("error");
    }
  }

  useEffect(() => {
    updateSessionInfo();
    setupWhiteLabelSettings();
    initPageBackground();
    window.addEventListener("session-update", handleUserSessionUpdate);
    window.addEventListener("focus", handleUserSessionOnPageFocus);
    getLogo();
  }, []);

  const PartnerBranding = ({ guiSettings }) => (
    <div>
      <a
        className="rb__title--container"
        href={partnerConfig.url}
        target="_blank"
        style={{ color: partnerConfig.bp_ui_settings.styles.bpWlHeader.color }}
      >
        {/* Comment this code block, might use this in the future  */}
            {/* {getLogoImg(
              guiSettings?.secondary_logo_path
                ? guiSettings?.secondary_logo_path
                : partnerConfig.logo_path,
        
            )} */}
        {/*****************************************/}

        {getLogoImg(partnerConfig.logo_path)}
      </a>
      <div className="powered-by-rb">POWERED BY RENTAL BEAST</div>
    </div>
  );

  const userAuthMobileItems = [
    {
      linkUrl: "/account",
      component: (
        <div>
          Manage Account <i className="far fa-user" />
        </div>
      ),
    },
    {
      component: (
        <div onClick={logout}>
          Logout <i className="fas fa-sign-out-alt" />
        </div>
      ),
    },
  ];

  return (
    <div className={`rb__header--container ${styles.bpWlHeader}`}>
      <div className="rb__header--content">
        {userData && Object.keys(userData).length > 0 && (
          <>
            <PartnerBranding guiSettings={guiSettings} />
            <BrokerLegacySettings
              userData={userData}
              setMobileMenuItems={setLegacyMobileItems}
              whiteLabel={true}
              className={`desktop--section-container ${styles.bpWlUserAccount}`}
            />
            <DynamicNav
              setMobileMenuItems={setBrokerMobileItems}
              className="desktop--section-container"
              features={featuresAccess}
              partnerKey={partnerKey}
            />
            <div className="mobile--nav">
              <MobileNav
                sections={[
                  { items: brokerMobileItems },
                  { items: legacyMobileItems },
                  { items: userAuthMobileItems },
                ]}
              />
            </div>
            <DuplicateEmailDialog />
          </>
        )}
      </div>
    </div>
  );
}

export interface HeaderMenuItem {
  clickUrl?: string;
  component: any;
}
