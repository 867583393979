import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RBModernHeader } from "../src/features/rb_modern_header/rb_modern_header";
import { WhiteLabelHeader } from "../src/features/rb_modern_header/white_label_header";
import { CmaHeader } from "../src/features/rb_modern_header/cma_header";

document.addEventListener("DOMContentLoaded", () => {
  const isWhiteLabel = $("body").attr("data-white-label") === "true";
  if (isWhiteLabel) {
    ReactDOM.render(
      <Router>
        <Switch>
          <Route exact path="/broker_portal/agent/:agentId/cma_tool/:cmaId">
            <CmaHeader />
          </Route>
          <Route path="/">
            <WhiteLabelHeader />
          </Route>
        </Switch>
      </Router>,
      document.getElementById("rb__header")
    );
  } else {
    ReactDOM.render(<RBModernHeader />, document.getElementById("rb__header"));
  }
});
